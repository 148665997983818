/* eslint-disable @next/next/no-img-element */
import { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { CONFIG_SITE } from 'constants/index';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import StickyFooterBanner from './StickyFooterBanner';
import SocialMediaLinks from './SocialMediaLinks';
import { isMobile } from 'react-device-detect';
import useGlobalStore from 'stores/global-store.store';
import Image from 'next/image';
import { Icon } from '@iconify/react';

const Footer = ({
  isHomepage = false,
  isLayoutDisabled = false,
  bottomFixedAlertImageClass = 'bottom-0',
}) => {
  const isXooply = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.xooply;
  const { isFixedAlertFraudImageVisible, setIsFixedAlertFraudImageVisible } = useGlobalStore();
  const buttonRef = useRef(null);
  const hasScrolledPastRef = useRef(false); // Track scroll-past state

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Calculate if user has scrolled past the button
        const hasScrolledPast = entry.boundingClientRect.top < 0;

        // Update the scroll-past flag
        if (hasScrolledPast) {
          hasScrolledPastRef.current = true;
        } else {
          hasScrolledPastRef.current = false;
        }

        // Show/hide based on scroll-past state
        if (hasScrolledPastRef.current) {
          setIsFixedAlertFraudImageVisible(false);
        } else {
          setIsFixedAlertFraudImageVisible(!entry.isIntersecting);
        }
      },
      {
        threshold: 0.1,
      },
    );

    let observerRefValue = null;

    if (buttonRef.current) {
      observer.observe(buttonRef.current);
      observerRefValue = buttonRef.current;
    }

    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue);
    };
  }, []);

  const d = new Date();
  const year = d.getFullYear();

  const templateSubject = `Kendala%20${CONFIG_SITE?.CONTENT?.COMPANY?.DOMAIN}`;
  const templateMessage = `Halo%20${CONFIG_SITE?.CONTENT?.COMPANY?.BRAND},%20bisa%20bantu%20saya?%0D%0ANama%20:%20%0D%0AE-mail%20:%20%0D%0AMasalah%20saya%20:%20`;

  const contacts = useMemo(() => {
    const list = [];

    if (CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.NUMBER) {
      list.push({
        id: 'whatsapp',
        text: CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.NUMBER,
        href: `${CONFIG_SITE?.CONTENT?.CONTACTS?.WHATSAPP?.LINK}?text=${templateMessage}`,
        iconImg: '/assets/icons/contact-whatsapp-footer.svg',
        iconify: <Icon icon="ic:baseline-email" width="21" height="21" />,
      });
    }
    if (CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.USERNAME) {
      list.push({
        id: 'telegram',
        text: CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.USERNAME,
        href: CONFIG_SITE?.CONTENT?.CONTACTS?.TELEGRAM.LINK,
        iconImg: '/assets/icons/contact-telegram-footer.svg',
        iconify: <Icon icon="ic:baseline-telegram" width="21" height="21" />,
      });
    }
    if (CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT) {
      list.push({
        id: 'email',
        text: CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT,
        href: `mailto:${CONFIG_SITE?.CONTENT?.CONTACTS?.EMAIL?.SUPPORT}?subject=${templateSubject}&body=${templateMessage}`,
        iconImg: '/assets/icons/contact-email-footer.svg',
        iconify: <Icon icon="mage:whatsapp-filled" width="21" height="21" />,
      });
    }

    return list;
  }, []);

  return (
    <>
      {CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.xooply && (
        // <StickyFooterBanner imgUrl="/assets/images/idfood-homepage-static-footer.png" />
        <>
          <div className="section-container" ref={buttonRef}>
            <Image
              src="https://storage.googleapis.com/xooply-assets/layout-sticky-image.webp"
              alt="waspada"
              width={1270}
              height={100}
              style={{
                width: '100%',
                height: 'auto',
              }}
              className="!rounded-lg"
            />
          </div>

          <div
            style={{
              display: isFixedAlertFraudImageVisible ? 'block' : 'none',
            }}
            className={`fixed ${bottomFixedAlertImageClass} left-0 right-0 bg-transparent z-10`}
            id="sticky-alert-image"
          >
            <div className="section-container !py-0">
              <img
                src="https://storage.googleapis.com/xooply-assets/layout-sticky-image.webp"
                alt="waspada"
                width={1270}
                height={100}
                className="w-full md:w-[735px] lg:w-[891px] xl:w-[1147px] 2xl:w-[1207px] h-auto"
              />
            </div>
          </div>
        </>
      )}
      <footer className="w-full px-3 md:px-5 relative">
        <div className=" py-[40px] max-w-screen-xl px-9 mx-auto">
          <Row gutter={[24, 36]}>
            <Col xs={24} md={24} lg={8}>
              <Link href="/">
                <a className="w-auto h-[40px] relative mr-7 flex mb-4">
                  <img src={CONFIG_SITE.LOGO_URL} alt="Logo" className="w-auto h-full" />
                </a>
              </Link>
              <p className="mb-4 w-[200px] text-left text-sm text-grayScale04">
                {CONFIG_SITE?.CONTENT?.ADDRESS_LIST.join(', ').replace(/,,/g, ',')}
              </p>
              <div className="mb-6">
                {contacts.length > 0 && (
                  <ul className="flex flex-col gap-2">
                    {contacts.map((contact) => (
                      <li key={contact.id}>
                        <a
                          className={`font-semibold flex items-center text-[#555555] ${
                            isLayoutDisabled
                              ? 'cursor-not-allowed'
                              : 'hover:text-theme-primary-main'
                          }`}
                          href={contact.href}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            if (isLayoutDisabled) {
                              e.preventDefault();
                            }
                          }}
                        >
                          <span className="h-[22px] w-[22px]">{contact.iconify}</span>
                          <span className="ml-1 text-xs">{contact.text}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </Col>
            <Col xs={24} md={12} lg={5}>
              <div className="pl-0">
                <h6 className="mb-4 text-lg font-semibold text-grayScale05">Perusahaan</h6>
                <ul className="list list-none flex flex-col space-y-2 text-grayScale04 font-normal text-sm">
                  <li>
                    <Link href="/about">
                      <a
                        tabIndex={0}
                        role="link"
                        className={
                          isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                        }
                        onClick={(e) => {
                          if (isLayoutDisabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        Tentang Kami
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/contact-us">
                      <a
                        tabIndex={0}
                        role="link"
                        className={
                          isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                        }
                        onClick={(e) => {
                          if (isLayoutDisabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        Kontak
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy-policy">
                      <a
                        tabIndex={0}
                        role="link"
                        className={
                          isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                        }
                        onClick={(e) => {
                          if (isLayoutDisabled) {
                            e.preventDefault();
                          }
                        }}
                      >
                        {isXooply ? 'Kebijakan Privasi' : 'Privacy Policy'}
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/terms-and-conditions">
                      <a className="hover:text-theme-primary-main">
                        {isXooply ? 'Syarat & Ketentuan' : 'Terms & Condition'}
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={24} md={12} lg={5}>
              <h6 className="mb-4 text-lg font-semibold grayScale04">Informasi</h6>
              <ul className="list list-none flex flex-col space-y-2 text-grayScale04 font-normal text-sm">
                <li>
                  <a
                    className={
                      isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                    }
                    href={
                      CONFIG_SITE?.CONTENT?.DOCUMENT_PANDUAN_PEMBELI_URL ||
                      '/assets/files/Panduan Pembeli.pdf'
                    }
                    target="_blank"
                    rel="noreferrer"
                    onClick={(e) => {
                      if (isLayoutDisabled) {
                        e.preventDefault();
                      }
                    }}
                  >
                    Panduan Pembeli
                  </a>
                </li>
                {CONFIG_SITE?.WHITELABEL_KEY !== WHITELABEL_KEYS.idfood && (
                  <li>
                    <a
                      className={
                        isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                      }
                      href={
                        CONFIG_SITE?.CONTENT?.DOCUMENT_PANDUAN_PENJUAL_URL ||
                        '/assets/files/Panduan Penjual.pdf'
                      }
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        if (isLayoutDisabled) {
                          e.preventDefault();
                        }
                      }}
                    >
                      Menjadi Penyedia
                    </a>
                  </li>
                )}
                <li>
                  <Link href="/panduan/cara-bayar">
                    <a
                      tabIndex={0}
                      role="link"
                      className={
                        isLayoutDisabled ? 'cursor-not-allowed' : 'hover:text-theme-primary-main'
                      }
                      onClick={(e) => {
                        if (isLayoutDisabled) {
                          e.preventDefault();
                        }
                      }}
                    >
                      Informasi Pembayaran
                    </a>
                  </Link>
                </li>
              </ul>
            </Col>
            <Col xs={24} md={24} lg={6}>
              <Row>
                <Col xs={24} md={12} lg={24} className="px-0 mb-8 md:mb-5 flex flex-col gap-1">
                  <h3>Metode Pembayaran</h3>
                  <div className="mt-3 flex flex-wrap gap-3">
                    {(CONFIG_SITE?.CONTENT?.FOOTER?.SUPPORTED_PAYMENT_LOGO_URLS ?? []).map(
                      (item, index) => (
                        <div key={`joined-company-logo-url-${index}`} className="self-center">
                          <Image
                            src={item}
                            width={80}
                            height={32}
                            style={{ width: '100%', height: 'auto' }}
                            className="object-contain object-center max-h-[32px] max-w-[80px]"
                          />
                        </div>
                      ),
                    )}
                  </div>
                </Col>
                <Col xs={24} md={12} lg={24} className="px-0 flex flex-col gap-1 xl:mt-0">
                  <h3>Jasa Pengiriman</h3>
                  <div className="mt-3 flex flex-wrap gap-3">
                    {(CONFIG_SITE?.CONTENT?.FOOTER?.SUPPORTED_LOGISTIC_LOGO_URLS ?? []).map(
                      (item, index) => (
                        <div key={`joined-company-logo-url-${index}`} className="self-center">
                          <Image
                            src={item}
                            width={80}
                            height={32}
                            style={{ width: '100%', height: 'auto' }}
                            className="object-contain object-center max-h-[32px] max-w-[80px]"
                          />
                        </div>
                      ),
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="my-10" />
          {isXooply && (
            <>
              <Row gutter={[24, 36]}>
                <Col xs={24} md={12} lg={8}>
                  <div className="px-0 flex flex-col gap-1">
                    <h3>Tergabung di</h3>
                    <div className="mt-3 flex flex-wrap gap-10">
                      {(CONFIG_SITE?.CONTENT?.FOOTER?.JOINED_COMPANY_LOGO_URLS ?? []).map(
                        (item, index) => (
                          <div key={`joined-company-logo-url-${index}`} className="self-center">
                            <Image
                              src={item}
                              width={80}
                              height={32}
                              style={{ width: '100%', height: 'auto' }}
                              className="object-contain object-center max-h-[32px] max-w-[80px]"
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={10}>
                  <div className="px-0 flex flex-col gap-1">
                    <h3>Sertifikasi</h3>
                    <div className="mt-3 flex flex-wrap gap-10">
                      {(CONFIG_SITE?.CONTENT?.FOOTER?.STANDARDS_AND_CERTIFICATIONS ?? []).map(
                        (item, index) => (
                          <div key={`joined-company-logo-url-${index}`} className="self-center">
                            <Image
                              src={item}
                              width={80}
                              height={32}
                              style={{ width: '100%', height: 'auto' }}
                              className="object-contain object-center max-h-[32px] max-w-[80px]"
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={24} lg={6}>
                  <SocialMediaLinks isLayoutDisabled={isLayoutDisabled} withoutTitle />
                </Col>
              </Row>
              <div className="pb-10 lg:mb-0" />
            </>
          )}
        </div>
      </footer>
      {/* Copyright Footer */}
      <div className="bg-white w-full px-3 md:px-5 relative">
        {/* footer Copyright */}
        <div className="flex flex-row py-5 border-t ">
          <div className="container mx-auto text-grayScale05 text-xs lg:text-md">
            <p>
              Copyright © {year} <strong>{CONFIG_SITE?.CONTENT?.COMPANY?.NAME ?? ''}.</strong> All
              rights reserved.
            </p>
            <p className="font-normal text-grayScale04 text-sm text-justify mt-2">
              {CONFIG_SITE?.CONTENT?.FOOTER?.SUMMARY}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.propTypes = {
  isHomepage: PropTypes.bool,
};

export default Footer;
