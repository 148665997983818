import { Icon } from '@iconify/react';
import { IconSVG } from '@utils/ui';
import { CONFIG_SITE } from 'constants/index';
import { useCallback, useMemo } from 'react';

const SocialMediaLinks = ({ isLayoutDisabled = false, withoutTitle = false }) => {
  const list = useMemo(() => CONFIG_SITE?.CONTENT?.SOCIAL_MEDIA_LINKS ?? [], []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const iconComponents = {
    facebook: <IconSVG.IconFacebookCircle width={24} height={24} />,
    twitterx: <IconSVG.IconTwitterX width={24} height={24} />,
    youtube: <IconSVG.IconYoutube width={24} height={24} />,
    instagram: <IconSVG.IconInstagram width={24} height={24} />,
    tiktok: <IconSVG.IconTiktok width={24} height={24} />,
    linkedin: <IconSVG.IconLinkedin width={24} height={24} />,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const iconComponentsFilled = {
    facebook: <Icon icon="ic:round-facebook" width="28" height="28" />,
    twitterx: <Icon icon="fa6-brands:square-x-twitter" width="28" height="28" />,
    youtube: <Icon icon="ri:youtube-fill" width="28" height="28" />,
    instagram: <Icon icon="ri:instagram-fill" width="28" height="28" />,
    tiktok: <Icon icon="mage:tiktok-circle" width="28" height="28" />,
    linkedin: <Icon icon="mdi:linkedin" width="28" height="28" />,
  };

  const getIconComponents = useCallback(
    (name = '') => {
      if (name in iconComponentsFilled) {
        const selectedIconComponent = iconComponentsFilled[name];
        return selectedIconComponent;
      }

      return null;
    },
    [iconComponentsFilled],
  );

  return (
    <div>
      {!withoutTitle && <h3>Temukan Kami di:</h3>}
      <div className="flex justify-left lg:justify-between flex-wrap mt-3 gap-2 pr-4">
        {(CONFIG_SITE?.CONTENT?.SOCIAL_MEDIA_LINKS ?? []).map((item, index) => {
          const iconComponent = getIconComponents(item.name);
          return (
            <a
              key={index}
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className={`text-[#898989] hover:text-theme-primary-main ${
                isLayoutDisabled ? 'cursor-not-allowed' : ''
              }`}
              onClick={(e) => {
                if (isLayoutDisabled) {
                  e.preventDefault();
                }
              }}
            >
              {iconComponent}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SocialMediaLinks;
