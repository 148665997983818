/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useMemo, useState } from 'react';
import { CategoryHeaderCarousel } from 'components/Carousel';
import { Popover, Tab, Transition } from '@headlessui/react';
import { Menu } from 'antd';
import { ArchiveIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import useCategoryStore from 'stores/category.store';
import useGetHeaderHeight from 'hooks/useGetHeaderHeight';
import { CONFIG_SITE } from 'constants/index';
import { WHITELABEL_KEYS } from 'global/constants/whitelabel-keys';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';

export const CategoryPopover = ({ isHomepage, isLayoutDisabled }) => {
  const router = useRouter();
  const { categories: categoriesStore } = useCategoryStore();
  const categories = useMemo(
    () => categoriesStore?.filter((category) => category?.name !== 'Voucher'),
    [categoriesStore],
  );
  const headerHeight = useGetHeaderHeight();
  const isCallpro = CONFIG_SITE?.WHITELABEL_KEY === WHITELABEL_KEYS.callpro;

  const convertMenuData = (data) =>
    data?.map((item) => {
      if (item?.childs?.length === 0) {
        return (
          <Menu.Item key={item?.id}>
            <Link href={item?.redirectUrl}>{item?.name}</Link>
          </Menu.Item>
        );
      }

      return (
        <Menu.SubMenu
          key={item?.id}
          title={item?.name}
          onTitleClick={() => {
            router.push(item?.redirectUrl);
          }}
        >
          {convertMenuData(item?.childs)}
        </Menu.SubMenu>
      );
    });

  const menuItems = !!categories && isCallpro && convertMenuData(categories);

  return (
    <>
      <Popover>
        {({ open, close }) => (
          <>
            <Popover.Button
              disabled={isLayoutDisabled}
              className={`
                ${open ? '' : 'text-opacity-90'}
                group focus:outline-none group flex items-center cursor-pointer disabled:cursor-not-allowed text-gray-800 text-base `}
            >
              {CONFIG_SITE.WHITELABEL_KEY === WHITELABEL_KEYS.idfood ? (
                <span className="relative rounded-lg py-1.5 bg-transparent text-white flex items-center justify-center w-[16px] h-[16px]">
                  <Image src="/assets/icons/icon-bars.svg" alt="icon-bars.svg" layout="fill" />
                </span>
              ) : (
                <>
                  <ArchiveIcon
                    width={20}
                    height={20}
                    color="#4F4F4F"
                    className={
                      isLayoutDisabled ? 'opacity-50' : 'group-hover:text-theme-primary-main'
                    }
                  />
                  <span
                    className={`py-[8px] w-[90px] rounded-lg text-base font-semibold ${
                      isLayoutDisabled ? 'opacity-50' : 'group-hover:text-theme-primary-main'
                    }`}
                  >
                    Kategori
                  </span>
                </>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Overlay
                className={clsx('fixed left-0 right-0 bottom-0 z-20 bg-black/[0.3]')}
                style={{ top: headerHeight }}
                onPointerDown={(e) => e.stopPropagation()}
              />
            </Transition>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 z-20 mt-3 w-full">
                {isCallpro ? (
                  <Menu className="absolute" mode="vertical">
                    {menuItems}
                  </Menu>
                ) : (
                  <div className="overflow-hidden p-[40px] rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
                    <div className="w-full">
                      <Tab.Group>
                        <Tab.List className="rounded-xl p-1">
                          <CategoryHeaderCarousel categories={categories} />
                        </Tab.List>
                        <Tab.Panels>
                          {categories?.map((category) => (
                            <Tab.Panel key={category.id} className="rounded-xl bg-white p-3">
                              <div className="flex flex-row items-start space-x-6">
                                <ul className="w-3/4 columns-3">
                                  {category?.childs?.map((item, idx) => (
                                    <li key={idx} className="relative py-1">
                                      <span
                                        className="cursor-pointer hover:underline text-base font-normal text-grayScale04 hover:text-theme-primary-main "
                                        onClick={() => {
                                          router.push(item?.redirectUrl);
                                          close();
                                        }}
                                      >
                                        {item.name}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                                {CONFIG_SITE?.CATEGORY_POPOVER_IMAGE_URL && (
                                  <div className="w-1/4 h-[120px]">
                                    <div className="flex h-full items-center justify-center bg-[#D9D9D9] rounded-[20px] text-grayScale04 overflow-hidden">
                                      <img
                                        className="h-full w-full object-center object-cover"
                                        src={CONFIG_SITE?.CATEGORY_POPOVER_IMAGE_URL}
                                        objectFit="cover"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Tab.Panel>
                          ))}
                        </Tab.Panels>
                      </Tab.Group>
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </>
  );
};

export default CategoryPopover;
